<template>
  <div>
    <b-card
      v-if="job"
      no-body
      class="card-apply-job"
    >
      <job-header
        :profile-error="profileError"
        :profile-valid="profileValid"
        :job="job"
        :job-remaining="jobRemaining"
        :job-closed="jobClosed"
        :time-remaining="timeRemaining"
        :find-department="findDepartment"
      />
      <b-card-body>
        <app-collapse>
          <app-collapse-item title="JOB DESCRIPTION">
            <b-card-text
              class="mb-2"
              v-html="job.description"
            >
            </b-card-text>
          </app-collapse-item>
        </app-collapse>

        <div class="apply-job-package bg-light-primary rounded">
          <div v-if="job.job_group">
            <sup class="text-body">
              <small>JOB GROUP</small>
            </sup>
            <h2 class="d-inline mr-25 ml-1">
              {{ job.job_group }}
            </h2>
          </div>
          <div>
            Requires
            <b-badge
              variant="light-primary"
              pill
            >
              {{ job.certificate }}
            </b-badge>
          </div>
        </div>

        <b-alert
          v-if="profileValidity && !checkRequirements"
          variant="primary"
          show
        >
          <div class="alert-body">
            <span><strong>Hey There!</strong> From the education information you
              provided, looks like you don't meet the minimum requirements which
              is a {{ job.certificate }} certificate. You can check out the
              <b-link
                class="text-primary"
                :to="{ name: 'jobs' }"
              ><strong>other advertised posts</strong></b-link>
              though.</span>
          </div>
        </b-alert>
        <b-alert
          v-if="!jobOpen"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Looks like you're too early
          </h4>
          <div class="alert-body">
            <span>
              This job opens on {{ formatDate(job.opening_date) }} and your
              browser date is {{ formatDate(new Date()) }},
              {{ timeRemaining(job.opening_date) }} days remaining. If you think
              it's wrong, set your date then refresh this page.</span>
          </div>
        </b-alert>
        <b-alert
          v-if="jobClosed"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Looks like you're late
          </h4>
          <div class="alert-body">
            <span>
              This job application closed on
              {{ formatDate(job.closing_date) }} and your browser date is
              {{ formatDate(new Date()) }},
              {{ -timeRemaining(job.closing_date) }} days have passed. If you
              think it's wrong, set your date then refresh this page.</span>
          </div>
        </b-alert>
      </b-card-body>
      <b-row>
        <b-col
          v-if="user.data.role === 'Admin'"
          :class="{
            'pr-0': !(
              user.data.role === 'Admin' &&
              !(profileValidity && requirementsMet && jobOpen && !jobClosed)
            ),
          }"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="warning"
            :to="{ name: 'job-edit', params: { id: job.id } }"
          >
            Edit Job
          </b-button>
        </b-col>
        <b-col
          v-if="profileValidity && requirementsMet && jobOpen && !jobClosed"
          :class="{
            'pl-0':
              user.data.role === 'Admin' &&
              profileValidity &&
              requirementsMet &&
              jobOpen &&
              !jobClosed,
          }"
        >
          <b-button
            v-if="jobOpen && !jobClosed"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :to="{ name: 'job-application', params: { id: job.id } }"
          >
            Apply For This Job
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-alert
      v-else
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        This is embarassing ...
      </h4>
      <div class="alert-body">
        <span>
          Something went wrong. Try<b-link
            class="text-danger"
            :to="{ name: 'jobs' }"
          ><strong> going back to jobs list</strong></b-link></span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
  VBPopover,
  BRow,
  BCol,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { formatDate } from '@core/utils/filter'

import JobHeader from './components/JobHeader.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    AppCollapse,
    AppCollapseItem,
    JobHeader,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      requirementsMet: false,
      profileValidity: false,
      profileError: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
        ? {
            data: this.$store.state.auth.user || {},
            education: this.$store.state.auth.userEducation || [],
            experience: this.$store.state.auth.userExperience || [],
            information: this.$store.state.auth.userInformation || {},
            referees: this.$store.state.auth.userReferees || [],
          }
        : false
    },
    jobOpen() {
      return moment(new Date()).isSameOrAfter(moment(this.job.opening_date))
    },
    jobClosed() {
      return moment(this.job.closing_date).isSameOrBefore(moment(new Date()))
    },
    job() {
      if (this.$route.params.id) {
        const details = this.$store.state.jobs.jobs.find(
          e => e.id === this.$route.params.id,
        )
        if (!details) {
          this.showToast(
            'Error',
            'Job details cannot be found at the moment. Please try again later.',
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
        }
        return details
      }
      return {}
    },
    departments() {
      return this.$store.state.departments.departments || []
    },
    checkRequirements() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.requirementsMet = false
      if (this.job.certificate && this.user) {
        const userCertificates = this.user.education.map(row => row.examination)
        const requirementsMet = userCertificates.includes(this.job.certificate)
        if (!requirementsMet) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.requirementsMet = requirementsMet
          return requirementsMet
        }
      }

      // perform other checks
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.requirementsMet = true
      return true
    },
    profileValid() {
      if (this.user) {
        const { user } = this
        let error = ''

        // checks
        const userCheck = !user.data.email_verified && user.birthdate !== null // TODO: Add email verification check
        const educationCheck = user.education.length >= 2
        const experienceCheck = user.experience.length > 0 // TODO: Add check to calculate the experience programatically
        const informationCheck =
          user.information.county !== null &&
          user.information.married !== null &&
          user.information.nationalID !== null &&
          user.information.religion !== null &&
          user.information.sub_county !== null &&
          user.information.ward !== null &&
          user.information.yearsOfExperience !== null
        const refereesCheck = user.referees.length >= 2

        if (!userCheck) {
          error +=
            'Your Basic Profile should be valid and email address should be verified '
        } else if (!informationCheck) {
          error += 'Your User Information should be valid'
        } else if (!educationCheck) {
          error +=
            'This job requires you to fill in past education and upload their relevant certificates with at least 2 levels'
        } else if (!experienceCheck) {
          error += 'You need to upload experience Details in your profile '
        } else if (!refereesCheck) {
          error +=
            'You need at least 2 referees to guarantee you in your job application '
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.profileValidity = true
          return true
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.profileError = error
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.profileValidity = false
      return false
    },
  },
  methods: {
    formatDate,
    timeRemaining(d) {
      const date = moment(d)
      return -moment().diff(date, 'days')
    },
    jobRemaining() {
      return moment(new Date()).unix() >= moment(this.job.opening_date)
    },
    jobPassed() {
      return (
        (this.jobRemaining
          ? this.timeRemaining(this.job.closing_date)
          : this.timeRemaining(this.job.opening_date)) < 0
      )
    },
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    findDepartment(id) {
      const result = this.departments.find(e => e.id === id)
      return result ? result.name : 'Unknown'
    },
  },
}
</script>

<style></style>
